/* eslint-disable global-require */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import InnerHTML from 'dangerously-set-html-content';
import { Link } from 'react-router-dom';
import '../../css/home.css';
import HeroBanner from './HeroBanner';

function HomePage() {
  const [htmlData, setHtmlData] = useState('<div></div>');
  const [recentAdditionsData, setRecentAdditionsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('static/homepage.html');
        const text = await response.text();
        setHtmlData(text);
      } catch (error) {
        console.log(error);
      }
    })();
    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/recent_additions`)
      .then((response) => response.json())
      .then((data) => {
        setRecentAdditionsData(data.recent_additions);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching Recent Additions:', error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="pre-content">
        <div>
          <div id="block-opa-theme-breadcrumbs">
            <div id="breadcrumbs-container" className="container">
              <nav role="navigation" aria-labelledby="system-breadcrumb">
                <h2 id="system-breadcrumb" className="visually-hidden">
                  Breadcrumb
                </h2>
                <ul className="breadcrumbs">
                  <li>
                    <Link id="skipLink" to="/">
                      Home
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <HeroBanner page="homepage" />
        <div
          className="views-element-container"
          id="block-views-block-homepage-hero-block-1"
        >
          <div className="block-views-block-homepage-hero-block-1">
            <div className="js-view-dom-id-0176fc946b1c2df0b5f911bd6146810094db8e6897febb20c3aa9c8fac3db926">
              <div className="container" id="banner_buttons_container">
                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <a href="getting-started">
                      <div className="banner_button" id="banner_button1">
                        <img
                          src="/images/rocket.png"
                          style={{ marginTop: '10%', height: '52%' }}
                          alt="Getting started rocket"
                        />
                        <br />
                        Getting Started
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <a href="datasets">
                      <div className="banner_button" id="banner_button2">
                        <img
                          src="/images/book-getty.png"
                          style={{ marginTop: '17%', height: '49%' }}
                          alt="Data Catalog books"
                        />
                        <br />
                        Data Catalog
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <a href="/user-guide">
                      <div className="banner_button" id="banner_button3">
                        <img
                          src="/images/gears-getty.png"
                          style={{ marginTop: '15%', height: '47%' }}
                          alt="API Gears"
                        />
                        <br />
                        API User Guide
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="container" id="home_page">
                <div className="row big_buttons">
                  <div className="col-sm-12 col-md-6 col-lg-7">
                    <h2 style={{ paddingLeft: '10px' }}>Recent Additions</h2>
                    {loading ? (
                      <p>Loading Recent Additions...</p>
                    ) : (
                      <div>
                        {recentAdditionsData.map((recent_additions) => (
                          <div key={recent_additions.id} className="row">
                            <a href={recent_additions.link}>
                              <h3>{recent_additions.heading}</h3>
                            </a>
                            <p>
                              {HTMLReactParser(recent_additions.description)}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-5">
                    <div className="row pt-0">
                      <h2>Featured Visualization</h2>

                      <div
                        className="col-sm-12 col-md-12 col-lg-12"
                        style={{ padding: '0px' }}
                      >
                        <a
                          target="_blank"
                          href="https://www.dol.gov/agencies/odep/research-evaluation/MAEmap"
                          rel="noreferrer"
                        >
                          <div id="featured_visualization_div">
                            <img
                              style={{ width: '100%' }}
                              src={require('../../images/home-map.png')}
                              alt="Map"
                            />
                            {/* <div className="visualizations_teaser_caption">
                              Insured Unemployment Rates
                              <div className="visualizations_teaser_caption_sub">
                                Mar 2020 &ndash; Mar 2021
                              </div>
                            </div> */}
                          </div>
                        </a>
                      </div>

                      <a href="/visualization-gallery" className="button">
                        Visit the Full Gallery
                      </a>
                    </div>
                    <div className="row">
                      <h2>Give Feedback</h2>
                      <p>
                        Contact us to provide feedback and help other developers
                        use Department of Labor open data.
                      </p>
                      <p>Some ideas for useful feedback:</p>
                      <ul>
                        <li>New datasets we should add</li>
                        <li>The usefulness of existing Metadata</li>
                        <li>Any improvements to the service we can make</li>
                        <li>
                          Share a link to your code repository (e.g. Github,
                          Gitlab, Bitbucket)
                        </li>
                      </ul>
                      <br />
                      <a href="contact-us" className="button">
                        {' '}
                        Contact Us{' '}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main role="main" className="cd-main-content">
        <article role="article" className="agency_homepage">
          <div className="layout layout--twocol-section layout--twocol-section--67-33">
            <div className="row">{/* <InnerHTML html={mapHtml} /> */}</div>
          </div>
        </article>
      </main>
    </>
  );
}

export default HomePage;
