/* eslint-disable */
import { React } from "react";
import $ from "jquery";

function TutorialText(props) {
  const { type } = props;
  $(document).ready(function () {
    $("#transcript_toggle_button")
      .off("click")
      .on("click", function (ev) {
        ev.preventDefault();

        if ($(this).text() === "Show transcript") {
          $(this).text("Hide transcript");
          $("#transcript_container").show();
        } else {
          $(this).text("Show transcript");
          $("#transcript_container").hide();
        }
      });
  });
  switch (type) {
    case "browseData":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about browsing data in the Open Data Catalog"
              src="https://www.youtube.com/embed/R4-09bZLn_w"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>How to browse datasets in the Data Catalog</h3>
            </p>
            <p>
              0:11
              <br />
              In this video, I will show you how to browse datasets in our Data
              Catalog.
            </p>

            <p>
              0:17
              <br />
              The Data Catalog contains a wide range of datasets available
              through our API, and you can use various filters to find the
              specific data you need.
            </p>

            <p>
              0:28
              <br />
              From the Open Data Portal homepage, you can click on the Data
              Catalog icon.
            </p>

            <p>
              0:33
              <br />
              Here you will find an inventory of all datasets available in our
              API.
            </p>

            <p>
              0:40
              <br />
              In the Data Catalog, you will notice the sidebar on the left-hand
              side of the screen.
            </p>

            <p>
              0:45
              <br />
              This sidebar contains filters that you can apply one at a time to
              refine your search.
            </p>

            <p>
              0:52
              <br />
              You can filter datasets by categories such as Child Labor,
              Enforcement, Training, and Workforce.
            </p>

            <p>
              1:01
              <br />
              Simply click on a category to see datasets related to that topic.
            </p>

            <p>
              1:07
              <br />
              Tags provide additional descriptors for datasets.
            </p>

            <p>
              1:11
              <br />
              You can use tags like accidents, fatalities, or other specific
              terms related to your search interest.
            </p>

            <p>
              1:21
              <br />
              If you are looking for datasets from a specific agency within the
              Department of Labor, you can filter by agency name using the
              agency filter.
            </p>

            <p>
              1:33
              <br />
              Once you have applied a filter, you can further refine your
              results by sorting the dataset names.
            </p>

            <p>
              1:42
              <br />
              By default, dataset names are sorted in ascending order, but you
              can change it to descending order if you prefer.
            </p>

            <p>
              1:53
              <br />
              After applying a filter and a sort, browse through the datasets
              listed.
            </p>

            <p>
              1:59
              <br />
              Each dataset entry provides a brief description to help you
              understand its contents.
            </p>

            <p>
              2:07
              <br />
              Once you find what you are looking for, click on the dataset name
              to access the dataset’s metadata and data.
            </p>

            <p>
              2:16
              <br />
              Now you know how to efficiently navigate our Data Catalog to find
              the datasets you need.
            </p>

            <p>
              2:23
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    case "registerApiKey":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about registering for an API Key"
              src="https://www.youtube.com/embed/r5xLq_o9pro"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>
                How to register for an API key to access metadata and data
              </h3>
            </p>
            <p>
              0:12
              <br />
              In this video, I will guide you through the steps to get an API
              key to access our metadata and data.
            </p>

            <p>
              0:19
              <br />
              From the Open Data Portal homepage, click on the Sign In or
              Register link.
            </p>

            <p>
              0:26
              <br />
              Here you will find a login.gov button you can click on to create
              an account.
            </p>

            <p>
              0:32
              <br />
              Next click Create an Account and enter your e-mail address.
            </p>

            <p>
              0:41
              <br />
              Read and accept the login.gov Rules of Use and click the submit
              button.
            </p>

            <p>
              0:51
              <br />
              Then follow the instructions in the e-mail sent to you to confirm
              your e-mail address.
            </p>

            <p>
              1:03
              <br />
              Now we are going to create a strong password that is 12 characters
              or longer.
            </p>

            <p>
              1:09
              <br />
              Next we are going to set up two factor authentication for the
              security of your account.
            </p>

            <p>
              1:17
              <br />
              There are several options and you can choose the one that works
              best for you.
            </p>

            <p>
              1:22
              <br />
              The text message authentication method is very easy, so I will
              show you how to set that up in this demo.
            </p>

            <p>
              1:32
              <br />
              First, enter your mobile number in the provided field.
            </p>

            <p>
              1:37
              <br />
              Then click on Send Code to receive a text message verification
              code to your mobile phone.
            </p>

            <p>
              1:45
              <br />
              Next, check your text messages for the verification code and enter
              the verification code you received into the corresponding field on
              the login.gov website.
            </p>

            <p>
              1:58
              <br />
              Once you click on submit to confirm the code, your two factor
              authentication will be activated.
            </p>

            <p>
              2:06
              <br />
              You can add additional authentication methods if you would like,
              but I am going to skip this for now.
            </p>

            <p>
              2:15
              <br />
              Finally, click Agree and continue.
            </p>

            <p>
              2:20
              <br />
              You will then be redirected to the Open Data Portal's API
              Registration Form.
            </p>

            <p>
              2:27
              <br />
              The next step is to fill out the questionnaire.
            </p>

            <p>
              2:30
              <br />
              The Department of Labor will use your information to prioritize
              the creation of tools and resources on the Open Data Portal for
              using the API.
            </p>

            <p>
              2:41
              <br />
              Then agree to the Terms of Service and click the register for an
              API Key button.
            </p>

            <p>
              2:50
              <br />
              Next, click on Go to API Keys to be redirected to the Open Data
              Portal's API Key Manager.
            </p>

            <p>
              3:00
              <br />
              There may be a short delay while the system incorporates your
              credentials, and you may need to wait for a few minutes and or
              refresh your screen before an API key is generated in your
              account.
            </p>

            <p>
              3:15
              <br />
              Your API key will look like a mixture of numbers, letters, and
              special characters.
            </p>

            <p>
              3:20
              <br />
              You can create additional keys, up to five, if you need to use
              them for different purposes, client applications, or automation.
            </p>

            <p>
              3:32
              <br />
              Now you are all set to explore our metadata and data using your
              API key.
            </p>

            <p>
              3:38
              <br />
              Thank you for registering and happy data discovery!
            </p>

            <p>
              3:42
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    case "queryBuilder":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about accessing data using the API Query Builder"
              src="https://www.youtube.com/embed/_Ha94qFG7kU"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>How to access data with the API Query Builder</h3>
            </p>
            <p>
              0:11
              <br />
              In this video, I will guide you through using the API Query
              Builder to effortlessly request data from the API.
            </p>

            <p>
              0:19
              <br />
              Before we get started, be sure that you have registered for an
              account on the Open Data Portal and are signed in.
            </p>

            <p>
              0:26
              <br />
              If you need help with the registration process, check out the
              video description for a link to a tutorial that can help you with
              that.
            </p>

            <p>
              0:35
              <br />
              To begin, navigate to any dataset page from the Data Catalog.
            </p>

            <p>
              0:40
              <br />
              For this demonstration, I will use an API training dataset.
            </p>

            <p>
              0:46
              <br />
              The dataset name is Training dataset (industries).
            </p>

            <p>
              0:51
              <br />
              First, let's learn more about our dataset.
            </p>

            <p>
              0:55
              <br />
              Scroll down to the Fields table and you will see that this dataset
              has three fields called Industry, Value and Year.
            </p>

            <p>
              1:06
              <br />
              Next, scroll down to the Data Preview.
            </p>

            <p>
              1:09
              <br />
              This will give you an idea of what the data look like before you
              make a request.
            </p>

            <p>
              1:16
              <br />
              Finally, scroll down to the API Query Builder, which provides you
              with a basic template for making API calls and allows you to
              interactively select which fields and records you want, choose how
              records are formatted and sorted, and generates an API request
              that you can use to get your desired data.
            </p>

            <p>
              1:39
              <br />
              Because you are already signed into the portal, the Authorize
              button is green, which indicates that your API key is in use and
              will be automatically populated into your data requests.
            </p>

            <p>
              1:53
              <br />
              If you were not signed in, the Authorize button would be red,
              which would indicate that you would not be able to request data.
            </p>

            <p>
              2:02
              <br />
              Let's start building an API request.
            </p>

            <p>
              2:06
              <br />
              Begin by expanding the API Query Builder to view the available
              parameters for customizing your data request.
            </p>

            <p>
              2:14
              <br />
              FORMAT allows you to pick a data format returned by the API.
            </p>

            <p>
              2:21
              <br />
              By default you will get JSON, but you can get CSV or XML instead.
            </p>

            <p>
              2:28
              <br />I am going to pick CSV because it is the most efficient
              output format and is widely usable in most software applications.
            </p>

            <p>
              2:38
              <br />
              LIMIT allows you to specify the maximum number of records returned
              by the API.
            </p>

            <p>
              2:45
              <br />
              By default you will get 10 records, but you can request up to 5
              megabytes of data, not exceeding 10,000 records per request.
            </p>

            <p>
              2:55
              <br />I am going to leave it at 10.
            </p>

            <p>
              2:58
              <br />
              OFFSET allows you to specify the number of records to skip from
              the top of a dataset.
            </p>

            <p>
              3:04
              <br />
              This parameter can be used in conjunction with the LIMIT parameter
              when you are requesting more than the maximum record limit for a
              dataset.
            </p>

            <p>
              3:13
              <br />
              Since we are not requesting more than the maximum record limit, we
              can leave this at 0.
            </p>

            <p>
              3:21
              <br />
              FIELDS allows you to select specific fields for your request.
            </p>

            <p>
              3:25
              <br />
              For example, if you only want industry and value, you can select
              these fields.
            </p>

            <p>
              3:33
              <br />
              This can be very useful at delivering only the data elements you
              need and reducing the size of data payloads when you are working
              with big datasets that have lots of variables.
            </p>

            <p>
              3:46
              <br />
              If you want all fields, don't make any selections because by
              default all fields will be returned.
            </p>

            <p>
              3:55
              <br />
              SORT allows you to sort records in ascending or descending order.
            </p>

            <p>
              4:01
              <br />I am going to use the default setting which is set to
              ascending order.
            </p>

            <p>
              4:07
              <br />
              SORT BY allows you to sort records by a single field.
            </p>

            <p>
              4:12
              <br />
              In this example I will sort the records by year.
            </p>

            <p>
              4:19
              <br />
              FILTER OBJECT allows you to get a subset of records from the API.
            </p>

            <p>
              4:26
              <br />
              Simply pick a pre-populated filter example and edit it.
            </p>

            <p>
              4:33
              <br />
              The filter operators we support are equals, not equals, greater
              than, less than, in, not in, and like. For this example, let's
              pick equals and filter the dataset records where industry equals
              A.
            </p>

            <p>
              4:54
              <br />
              Next, replace the text “YOUR_FIELD” with industry and “YOUR_VALUE”
              with A.
            </p>

            <p>
              5:04
              <br />
              Now, we are ready to execute the API query.
            </p>

            <p>
              5:11
              <br />
              If everything is successful, you will see data in the response
              body as well as the response code 200.
            </p>

            <p>
              5:20
              <br />
              You will have the option to download the data once directly from
              the response body, or you can use a curl command or request URL to
              automate workflows.
            </p>

            <p>
              5:32
              <br />
              This allows you to regularly retrieve your customized data
              requests from the API with the latest available data from our
              database.
            </p>

            <p>
              5:41
              <br />
              This feature distinguishes our API from other data download
              methods.
            </p>

            <p>
              5:47
              <br />I am going to use the request URL which can be executed
              within a web browser or external software like Python, R, Excel
              and dashboarding applications.
            </p>

            <p>
              6:00
              <br />
              For simplicity, copy and paste the request URL into a web browser
              and get your CSV data.
            </p>

            <p>
              6:14
              <br />
              Because I have Microsoft Excel on my computer, I can open up this
              CSV file download as a tabular spreadsheet.
            </p>

            <p>
              6:26
              <br />
              Now you know how to use the API Query Builder to access our data.
            </p>

            <p>
              6:31
              <br />
              Try modifying the parameters and re-running the request on your
              own.
            </p>

            <p>
              6:36
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    case "orientationVideo":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Department of Labor Open Data Portal and API"
              src="https://www.youtube.com/embed/Bvzr1jlepP4"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>

          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <strong>
                Introduction - Kick off with a brief overview and motivation for
                creating the portal.
              </strong>{" "}
            </p>

            <p>
              {" "}
              0:00
              <br />
              in this session today I'm going to be
            </p>

            <p>
              {" "}
              0:03
              <br />
              talking to you about the Department of
            </p>

            <p>
              {" "}
              0:05
              <br />
              Labor Open Data Portal and the API. My name
            </p>

            <p>
              {" "}
              0:08
              <br />
              is Chris Morris and I'm with the Office
            </p>

            <p>
              {" "}
              0:10
              <br />
              of Data Governance. I'm very excited to
            </p>

            <p>
              {" "}
              0:13
              <br />
              talk to you about our new open data
            </p>

            <p>
              {" "}
              0:15
              <br />
              services. So first thing I wanted to
            </p>

            <p>
              {" "}
              0:18
              <br />
              reiterate our commitment to the public
            </p>

            <p>
              {" "}
              0:20
              <br />
              that we recognize that our data are a
            </p>

            <p>
              {" "}
              0:23
              <br />
              strategic asset and we're committed to
            </p>

            <p>
              {" "}
              0:25
              <br />
              making them available in machine
            </p>

            <p>
              {" "}
              0:27
              <br />
              readable formats and easily findable
            </p>

            <p>
              {" "}
              0:30
              <br />
              accessible and usable. The Open Data
            </p>

            <p>
              {" "}
              0:34
              <br />
              Portal and API is a big part of our
            </p>

            <p>
              {" "}
              0:36
              <br />
              open data plan and we're going to be
            </p>

            <p>
              {" "}
              0:39
              <br />
              doing a lot more in the area of open
            </p>

            <p>
              {" "}
              0:40
              <br />
              data so I encourage you and invite you
            </p>

            <p>
              {" "}
              0:42
              <br />
              to read our open data plan to learn more
            </p>

            <p>
              {" "}
              0:45
              <br />
              about our efforts. I also just wanted to
            </p>

            <p>
              {" "}
              0:47
              <br />
              thank our public stakeholders. We did an
            </p>

            <p>
              {" "}
              0:50
              <br />
              Outreach a request for information in
            </p>

            <p>
              {" "}
              0:53
              <br />
              2022 to better understand the needs of
            </p>

            <p>
              {" "}
              0:56
              <br />
              workers and other DOL stakeholders who
            </p>

            <p>
              {" "}
              0:58
              <br />
              consume our data and we really learned a
            </p>

            <p>
              {" "}
              1:01
              <br />
              lot and got a lot of valuable feedback.
            </p>

            <p>
              {" "}
              1:03
              <br />
              Some of the comments that we received
            </p>

            <p>
              {" "}
              1:05
              <br />
              focused on: the need to provide timely
            </p>

            <p>
              {" "}
              1:07
              <br />
              and accurate data; provide access to
            </p>

            <p>
              {" "}
              1:11
              <br />
              additional data sources that would
            </p>

            <p>
              {" "}
              1:13
              <br />
              benefit public policy, program analysis,
            </p>

            <p>
              {" "}
              1:16
              <br />
              and evidence-based decision making; and
            </p>

            <p>
              {" "}
              1:19
              <br />
              suggestions for providing more optimal
            </p>

            <p>
              {" "}
              1:21
              <br />
              modes of data access. So we heard that
            </p>

            <p>
              {" "}
              1:24
              <br />
              feedback and we prioritized it in our
            </p>

            <p>
              {" "}
              1:26
              <br />
              functional requirements for this new
            </p>

            <p>
              {" "}
              1:28
              <br />
              service and I think that you all are
            </p>

            <p>
              {" "}
              1:30
              <br />
              going to be very happy with what you see
            </p>

            <p>
              {" "}
              1:32
              <br />
              here. We were also able to take data set
            </p>

            <p>
              {" "}
              1:35
              <br />
              suggestions specifically there was a
            </p>

            <p>
              {" "}
              1:38
              <br />a need to access the Employment and
            </p>

            <p>
              {" "}
              1:40
              <br />
              Training Administration data with the API.
            </p>

            <p>
              {" "}
              1:43
              <br />
              Some of those data sets are very large
            </p>

            <p>
              {" "}
              1:45
              <br />
              and so the API is really a great way to
            </p>

            <p>
              {" "}
              1:47
              <br />
              serve that data out. So we were able to
            </p>

            <p>
              {" "}
              1:49
              <br />
              get a lot of those data sets
            </p>

            <p>
              {" "}
              <strong>
                Open Data Portal - Discover the features of our new portal.
              </strong>{" "}
            </p>

            <p>
              {" "}
              1:52
              <br />
              in. Ah on this slide I'm showing you a
            </p>

            <p>
              {" "}
              1:55
              <br />
              screenshot of the Open Data Portal
            </p>

            <p>
              {" "}
              1:57
              <br />
              landing page this is a centralized page
            </p>

            <p>
              {" "}
              2:00
              <br />
              for DOL data. So when you land here some
            </p>

            <p>
              {" "}
              2:03
              <br />
              of the key features; we have a data
            </p>

            <p>
              {" "}
              2:05
              <br />
              catalog for browsing data across
            </p>

            <p>
              {" "}
              2:08
              <br />
              agencies and we put a standard so that for
            </p>

            <p>
              {" "}
              2:12
              <br />
              each data set that goes out to the
            </p>

            <p>
              {" "}
              2:14
              <br />
              Public, it's going to have associated with
            </p>

            <p>
              {" "}
              2:16
              <br />
              It, comprehensive metadata that describe
            </p>

            <p>
              {" "}
              2:19
              <br />
              the variables in our data sets. And we
            </p>

            <p>
              {" "}
              2:21
              <br />
              think that this is really going to help
            </p>

            <p>
              {" "}
              2:23
              <br />
              people to work more efficiently when
            </p>

            <p>
              {" "}
              2:24
              <br />
              they have a consistent way to understand
            </p>

            <p>
              {" "}
              2:27
              <br />
              the contents of the data set to see if
            </p>

            <p>
              {" "}
              2:29
              <br />
              it's suitable for their intended
            </p>

            <p>
              {" "}
              2:31
              <br />
              purposes for using the data even prior
            </p>

            <p>
              {" "}
              2:34
              <br />
              to accessing it. Also the key feature
            </p>

            <p>
              {" "}
              2:37
              <br />
              on here is our API web service that
            </p>

            <p>
              {" "}
              2:40
              <br />
              serves customizable data extracts in
            </p>

            <p>
              {" "}
              2:43
              <br />
              machine readable formats so as a user
            </p>

            <p>
              {" "}
              2:46
              <br />
              you'll be able to access metadata and
            </p>

            <p>
              {" "}
              2:48
              <br />
              data in machine readable format of your
            </p>

            <p>
              {" "}
              2:50
              <br />
              choice on demand exactly how you like it.
            </p>

            <p>
              {" "}
              2:54
              <br />
              And then we have a number of resources
            </p>

            <p>
              {" "}
              2:56
              <br />
              including a User Guide, API Examples,
            </p>

            <p>
              {" "}
              2:59
              <br />
              Video Tutorials, Code Templates, and of
            </p>

            <p>
              {" "}
              3:02
              <br />
              course a Frequently Asked Questions
            </p>

            <p>
              {" "}
              <strong>
                Registration - Learn how to sign up for an API key and gain
                access.
              </strong>{" "}
            </p>

            <p>
              {" "}
              3:04
              <br />
              page. So in order to start accessing DOL
            </p>

            <p>
              {" "}
              3:08
              <br />
              metadata and data, the first step is to
            </p>

            <p>
              {" "}
              3:11
              <br />
              register. So at this time I'm going to
            </p>

            <p>
              {" "}
              3:14
              <br />
              ask our team to drop a link to
            </p>

            <p>
              {" "}
              3:16
              <br />
              registration in. I encourage you all to
            </p>

            <p>
              {" "}
              3:18
              <br />
              register it's a very easy process. You
            </p>

            <p>
              {" "}
              3:21
              <br />
              can do it at dataportal.dol.gov/registration
            </p>

            <p>
              {" "}
              3:24
              <br />
              We are authenticating
            </p>

            <p>
              {" "}
              3:27
              <br />
              with login.gov so if you don't already
            </p>

            <p>
              {" "}
              3:30
              <br />
              have an account it's very easy. Just
            </p>

            <p>
              {" "}
              3:32
              <br />
              create a login.gov account. It's going to
            </p>

            <p>
              {" "}
              3:35
              <br />
              ask for an email address a password.
            </p>

            <p>
              {" "}
              3:37
              <br />
              You'll confirm your email address. You'll
            </p>

            <p>
              {" "}
              3:39
              <br />
              set up two-factor authentication for
            </p>

            <p>
              {" "}
              3:42
              <br />
              the security of your account and then
            </p>

            <p>
              {" "}
              3:44
              <br />
              you'll fill out a brief API registration
            </p>

            <p>
              {" "}
              3:46
              <br />
              form that we have and if you already
            </p>

            <p>
              {" "}
              3:49
              <br />
              have an account just simply sign in
            </p>

            <p>
              {" "}
              3:51
              <br />
              through the portal and then fill out the
            </p>

            <p>
              {" "}
              3:53
              <br />
              form. If you are a federal government
            </p>

            <p>
              {" "}
              3:56
              <br />
              employee there's simplified access with
            </p>

            <p>
              {" "}
              3:58
              <br />
              your PIV card which is a really nice
            </p>

            <p>
              {" "}
              4:00
              <br />
              perk. So once you complete that
            </p>

            <p>
              {" "}
              4:03
              <br />
              registration process, you're going to
            </p>

            <p>
              {" "}
              4:05
              <br />
              land on the API key manager page. If you
            </p>

            <p>
              {" "}
              4:08
              <br />
              don't see your key right away, you may
            </p>

            <p>
              {" "}
              4:10
              <br />
              need to give it a couple minutes or
            </p>

            <p>
              {" "}
              4:12
              <br />
              refresh your screen while the system is
            </p>

            <p>
              {" "}
              4:14
              <br />
              incorporating your credentials. Once
            </p>

            <p>
              {" "}
              4:18
              <br />
              you are signed in you're going to see
            </p>

            <p>
              {" "}
              4:20
              <br />a place at the top of the screen where
            </p>

            <p>
              {" "}
              4:22
              <br />
              you can access your API key at any time.
            </p>

            <p>
              {" "}
              4:25
              <br />
              It's right above the top navigation bar.
            </p>

            <p>
              {" "}
              4:27
              <br />
              So if you ever wonder, “Hey where's my API
            </p>

            <p>
              {" "}
              4:30
              <br />
              key?” you know that you can sign in and
            </p>

            <p>
              {" "}
              4:32
              <br />
              find it easily right there at the top.
            </p>

            <p>
              {" "}
              4:35
              <br />
              Your API key will look like a mixture of
            </p>

            <p>
              {" "}
              4:38
              <br />
              numbers, letters, and special characters.
            </p>

            <p>
              {" "}
              4:41
              <br />
              So this is unique for you, for your
            </p>

            <p>
              {" "}
              4:44
              <br />
              access to metadata and data. So don't
            </p>

            <p>
              {" "}
              4:46
              <br />
              share it with other others. It's just for
            </p>

            <p>
              {" "}
              4:48
              <br />
              your use of the API. And then within this
            </p>

            <p>
              {" "}
              4:52
              <br />
              key manager you can create up to five
            </p>

            <p>
              {" "}
              4:54
              <br />
              additional keys if you need them to use
            </p>

            <p>
              {" "}
              4:57
              <br />
              them for different purposes client
            </p>

            <p>
              {" "}
              4:59
              <br />
              application, or
            </p>

            <p>
              {" "}
              <strong>
                Data Catalog - Explore our collection of datasets.
              </strong>{" "}
            </p>

            <p>
              {" "}
              5:02
              <br />
              automation so once you're registered you
            </p>

            <p>
              {" "}
              5:04
              <br />
              have your API key and you're signed in
            </p>

            <p>
              {" "}
              5:07
              <br />
              now it's time to look for data so up
            </p>

            <p>
              {" "}
              5:10
              <br />
              here on the top navigation bar there's
            </p>

            <p>
              {" "}
              5:12
              <br />
              an easy place to find the data catalog
            </p>

            <p>
              {" "}
              5:15
              <br />
              So within the data catalog you can look
            </p>

            <p>
              {" "}
              5:17
              <br />
              at all the data sets we have across
            </p>

            <p>
              {" "}
              5:21
              <br />
              do and there's a couple of ways to
            </p>

            <p>
              {" "}
              5:24
              <br />
              refine the results that are shown here
            </p>

            <p>
              {" "}
              5:27
              <br />
              so we have a filtering capability here
            </p>

            <p>
              {" "}
              5:29
              <br />
              on the left sidebar you can either
            </p>

            <p>
              {" "}
              5:31
              <br />
              filter by category so we have broad
            </p>

            <p>
              {" "}
              5:34
              <br />
              categories like Workforce enforcement
            </p>

            <p>
              {" "}
              5:37
              <br />
              things of that nature we have additional
            </p>

            <p>
              {" "}
              5:41
              <br />
              descriptors in the tags so if you're
            </p>

            <p>
              {" "}
              5:43
              <br />
              looking for particular Concepts like
            </p>

            <p>
              {" "}
              5:46
              <br />
              accident or injury you can filter the
            </p>

            <p>
              {" "}
              5:49
              <br />
              data that way or if you're just looking
            </p>

            <p>
              {" "}
              5:51
              <br />
              for data by agency you can use the
            </p>

            <p>
              {" "}
              5:53
              <br />
              agency filter and that will filter the
            </p>

            <p>
              {" "}
              5:56
              <br />
              results down in addition you'll be
            </p>

            <p>
              {" "}
              5:59
              <br />
              able to sort your results by data set
            </p>

            <p>
              {" "}
              6:02
              <br />
              name in ascending or decending order and
            </p>

            <p>
              {" "}
              6:05
              <br />
              once you have your filtered results
            </p>

            <p>
              {" "}
              6:08
              <br />
              simply read through the data set
            </p>

            <p>
              {" "}
              6:11
              <br />
              descriptions and get a sense of if the
            </p>

            <p>
              {" "}
              6:13
              <br />
              data are going to be helpful for your
            </p>

            <p>
              {" "}
              6:15
              <br />
              research needs and then once you click
            </p>

            <p>
              {" "}
              6:18
              <br />
              on a link to one of these data sets it's
            </p>

            <p>
              {" "}
              6:21
              <br />
              going to take you to an individual data
            </p>

            <p>
              {" "}
              <strong>
                Dataset Pages.Learn about the structure and content of dataset
                pages.
              </strong>{" "}
            </p>

            <p>
              {" "}
              6:23
              <br />
              set page now we've structured the layout
            </p>

            <p>
              {" "}
              6:26
              <br />
              of these pages to be consistent across
            </p>

            <p>
              {" "}
              6:28
              <br />
              the board and that was really to help
            </p>

            <p>
              {" "}
              6:30
              <br />
              with the user experience we want it to
            </p>

            <p>
              {" "}
              6:32
              <br />
              be predictable and consistent right
            </p>

            <p>
              {" "}
              6:34
              <br />
              findability and accessibility is a key
            </p>

            <p>
              {" "}
              6:37
              <br />
              thing that we have been working to
            </p>

            <p>
              {" "}
              6:39
              <br />
              improve through this centralized portal
            </p>

            <p>
              {" "}
              6:43
              <br />
              so each of these pages in addition to
            </p>

            <p>
              {" "}
              6:45
              <br />
              data set information you're going to
            </p>

            <p>
              {" "}
              6:47
              <br />
              have field information so you'll know
            </p>

            <p>
              {" "}
              6:49
              <br />
              what the field names are the
            </p>

            <p>
              {" "}
              6:51
              <br />
              descriptions the data types and we're
            </p>

            <p>
              {" "}
              6:54
              <br />
              going to have comprehensive metadata so
            </p>

            <p>
              {" "}
              6:56
              <br />
              there'll be an expanded set of metadata
            </p>

            <p>
              {" "}
              6:58
              <br />
              elements to describe the variables in
            </p>

            <p>
              {" "}
              7:00
              <br />
              each data set under that we have a 10
            </p>

            <p>
              {" "}
              7:03
              <br />
              record data set preview so you get an
            </p>

            <p>
              {" "}
              7:05
              <br />
              idea of what the data look like and then
            </p>

            <p>
              {" "}
              7:08
              <br />
              we have our API query Builder which is a
            </p>

            <p>
              {" "}
              7:11
              <br />
              simple interface for building API
            </p>

            <p>
              {" "}
              7:13
              <br />
              requests and below that we have code
            </p>

            <p>
              {" "}
              7:16
              <br />
              Snippets for every data set and that's
            </p>

            <p>
              {" "}
              7:19
              <br />
              going to be for people that are seeking
            </p>

            <p>
              {" "}
              7:21
              <br />
              to interact with the API
            </p>

            <p>
              {" "}
              7:25
              <br />
              programmatically so shown here on this
            </p>

            <p>
              {" "}
              7:28
              <br />
              page is an example of what one of these
            </p>

            <p>
              {" "}
              7:30
              <br />
              data set Pages look like and this is for
            </p>

            <p>
              {" "}
              7:33
              <br />
              the employment insurance weekly claims
            </p>

            <p>
              {" "}
              7:36
              <br />
              data so I want to draw your attention
            </p>

            <p>
              {" "}
              7:38
              <br />
              here at the top at the top of each
            </p>

            <p>
              {" "}
              7:41
              <br />
              page we have bookmarks which make for
            </p>

            <p>
              {" "}
              7:44
              <br />
              easy navigation through an individual
            </p>

            <p>
              {" "}
              7:47
              <br />
              data set page so you can click and go
            </p>

            <p>
              {" "}
              7:49
              <br />
              directly to the area that's of most
            </p>

            <p>
              {" "}
              7:51
              <br />
              interest to you so let's talk about the
            </p>

            <p>
              {" "}
              7:54
              <br />
              different elements that you'll find on
            </p>

            <p>
              {" "}
              7:56
              <br />
              one of these pages so at the very top is
            </p>

            <p>
              {" "}
              7:59
              <br />
              going to be your data set information
            </p>

            <p>
              {" "}
              8:01
              <br />
              you'll have your data set description
            </p>

            <p>
              {" "}
              8:04
              <br />
              the publishing agency how frequently the
            </p>

            <p>
              {" "}
              8:07
              <br />
              data are refreshed how many fields are
            </p>

            <p>
              {" "}
              8:10
              <br />
              in the data set what tags are
            </p>

            <p>
              {" "}
              8:12
              <br />
              associated with the data set and very
            </p>

            <p>
              {" "}
              8:15
              <br />
              importantly how to contact us about the
            </p>

            <p>
              {" "}
              8:17
              <br />
              data set so there's a link for contact
            </p>

            <p>
              {" "}
              8:19
              <br />
              us there directly below the data set
            </p>

            <p>
              {" "}
              8:22
              <br />
              information will be the field
            </p>

            <p>
              {" "}
              8:24
              <br />
              information so this is where you'll get
            </p>

            <p>
              {" "}
              8:27
              <br />
              your field names your field descriptions
            </p>

            <p>
              {" "}
              8:29
              <br />
              and the data types in terms of how you
            </p>

            <p>
              {" "}
              8:32
              <br />
              should be working with them in your
            </p>

            <p>
              {" "}
              <strong>
                How to Access Metadata.Learn how to access comprehensive
                metadata.
              </strong>{" "}
            </p>

            <p>
              {" "}
              8:35
              <br />
              software right underneath of the
            </p>

            <p>
              {" "}
              8:37
              <br />
              fields table is the comprehensive
            </p>

            <p>
              {" "}
              8:40
              <br />
              expanded metadata so if you're signed
            </p>

            <p>
              {" "}
              8:43
              <br />
              into the service there's going to be a
            </p>

            <p>
              {" "}
              8:45
              <br />
              link that you can click on to access the
            </p>

            <p>
              {" "}
              8:47
              <br />
              metadata so simply click on that link
            </p>

            <p>
              {" "}
              8:50
              <br />
              and your web browser will ask our API
            </p>

            <p>
              {" "}
              8:53
              <br />
              service to retrieve your metadata what I
            </p>

            <p>
              {" "}
              8:56
              <br />
              love about apis is unlike a traditional
            </p>

            <p>
              {" "}
              8:59
              <br />
              link that you might click on a website
            </p>

            <p>
              {" "}
              9:01
              <br />
              to download a metadata or a data file is
            </p>

            <p>
              {" "}
              9:04
              <br />
              that the API sits on top of a database
            </p>

            <p>
              {" "}
              9:07
              <br />
              so you're ultimately going to get the
            </p>

            <p>
              {" "}
              9:09
              <br />
              latest available information and so it
            </p>

            <p>
              {" "}
              9:12
              <br />
              it is linked to a dynamic source and the
            </p>

            <p>
              {" "}
              9:15
              <br />
              way that the API works is similar to you
            </p>

            <p>
              {" "}
              9:18
              <br />
              think about a waiter in a restaurant so
            </p>

            <p>
              {" "}
              9:20
              <br />
              you sit down at the table and you want
            </p>

            <p>
              {" "}
              9:22
              <br />
              to place an order in this case we're
            </p>

            <p>
              {" "}
              9:24
              <br />
              ordering data right we're ordering data
            </p>

            <p>
              {" "}
              9:26
              <br />
              on demand so the server says what
            </p>

            <p>
              {" "}
              9:28
              <br />
              metadata can I get for you and how would
            </p>

            <p>
              {" "}
              9:31
              <br />
              you like it formatted and so because the
            </p>

            <p>
              {" "}
              9:34
              <br />
              API is a web service we're going to
            </p>

            <p>
              {" "}
              9:36
              <br />
              create a web link in order to make our
            </p>

            <p>
              {" "}
              9:40
              <br />
              request so I want to talk a little bit
            </p>

            <p>
              {" "}
              9:42
              <br />
              about the structure of this metadata API
            </p>

            <p>
              {" "}
              9:46
              <br />
              request the portal will automatically
            </p>

            <p>
              {" "}
              9:49
              <br />
              put this together so you don't have to
            </p>

            <p>
              {" "}
              9:50
              <br />
              worry about building any kind of web
            </p>

            <p>
              {" "}
              9:52
              <br />
              link but this is kind of a tease for
            </p>

            <p>
              {" "}
              9:55
              <br />
              the workshops that we're going to be
            </p>

            <p>
              {" "}
              9:56
              <br />
              doing where we'll be talking more in
            </p>

            <p>
              {" "}
              9:58
              <br />
              depth about how the API is structured
            </p>

            <p>
              {" "}
              10:00
              <br />
              and how it works but included in this
            </p>

            <p>
              {" "}
              10:03
              <br />
              link the first part tells you the
            </p>

            <p>
              {" "}
              10:06
              <br />
              location of where the data services are
            </p>

            <p>
              {" "}
              10:09
              <br />
              which is api. dol.gov
            </p>

            <p>
              {" "}
              10:12
              <br />
              V4 the method is get as we're going to
            </p>

            <p>
              {" "}
              10:15
              <br />
              be getting
            </p>

            <p>
              {" "}
              10:17
              <br />
              information the next part is agency
            </p>

            <p>
              {" "}
              10:20
              <br />
              which is
            </p>

            <p>
              {" "}
              10:21
              <br />
              ETA and then the endpoint is the data
            </p>

            <p>
              {" "}
              10:24
              <br />
              set name so UI National weekly claims
            </p>

            <p>
              {" "}
              10:27
              <br />
              because in this example we would be
            </p>

            <p>
              {" "}
              10:29
              <br />
              getting metadata for that particular
            </p>

            <p>
              {" "}
              10:32
              <br />
              data set then you get to pick what
            </p>

            <p>
              {" "}
              10:34
              <br />
              format you want right by default we
            </p>

            <p>
              {" "}
              10:38
              <br />
              have CSV here so that for those of you
            </p>

            <p>
              {" "}
              10:40
              <br />
              that want to open up the metadata file
            </p>

            <p>
              {" "}
              10:42
              <br />
              like in an Excel spreadsheet it's very
            </p>

            <p>
              {" "}
              10:45
              <br />
              easy to do that CSV stands for comma
            </p>

            <p>
              {" "}
              10:48
              <br />
              separated values it's a nonproprietary
            </p>

            <p>
              {" "}
              10:51
              <br />
              universal data format and it can be open
            </p>

            <p>
              {" "}
              10:54
              <br />
              using common text editors like notepad
            </p>

            <p>
              {" "}
              10:57
              <br />
              but as I said if you prefer to see this
            </p>

            <p>
              {" "}
              10:59
              <br />
              in like a tabular spreadsheet it's
            </p>

            <p>
              {" "}
              11:01
              <br />
              really easy to display that in software
            </p>

            <p>
              {" "}
              11:04
              <br />
              like Microsoft
            </p>

            <p>
              {" "}
              11:06
              <br />
              Excel the last part of the request
            </p>

            <p>
              {" "}
              11:10
              <br />
              will include your API key so that's why
            </p>

            <p>
              {" "}
              11:13
              <br />
              you need to register for that and then
            </p>

            <p>
              {" "}
              11:15
              <br />
              if you see right here within that URL
            </p>

            <p>
              {" "}
              11:18
              <br />
              it includes you know that we're asking
            </p>

            <p>
              {" "}
              11:20
              <br />
              for metadata so again we'll talk
            </p>

            <p>
              {" "}
              11:23
              <br />
              about this more at the upcoming
            </p>

            <p>
              {" "}
              11:24
              <br />
              workshops but what is really great
            </p>

            <p>
              {" "}
              11:27
              <br />
              about using apis is that you have a
            </p>

            <p>
              {" "}
              11:29
              <br />
              consistent and predictable way for
            </p>

            <p>
              {" "}
              11:31
              <br />
              accessing metadata and data it'd be
            </p>

            <p>
              {" "}
              11:34
              <br />
              very easy to swap out a different
            </p>

            <p>
              {" "}
              11:36
              <br />
              endpoint name like let's say you wanted
            </p>

            <p>
              {" "}
              11:38
              <br />
              to get metadata for the state weekly
            </p>

            <p>
              {" "}
              11:40
              <br />
              claims you could just change out
            </p>

            <p>
              {" "}
              11:42
              <br />
              National weekly claims with State weekly
            </p>

            <p>
              {" "}
              11:44
              <br />
              claims and the API service will very
            </p>

            <p>
              {" "}
              11:47
              <br />
              easily pull that metadata for
            </p>

            <p>
              {" "}
              <strong>
                Value of Metadata.Understand the importance of using metadata.
              </strong>{" "}
            </p>

            <p>
              {" "}
              11:50
              <br />
              you so I want to take a minute to talk
            </p>

            <p>
              {" "}
              11:52
              <br />
              about the value of our metadata our
            </p>

            <p>
              {" "}
              11:55
              <br />
              metadata can really help you to
            </p>

            <p>
              {" "}
              11:58
              <br />
              understand the data dat set it describes
            </p>

            <p>
              {" "}
              12:00
              <br />
              it really well so you can see if it
            </p>

            <p>
              {" "}
              12:01
              <br />
              meets your needs prior to access I'm
            </p>

            <p>
              {" "}
              12:04
              <br />
              going to give you a couple of examples
            </p>

            <p>
              {" "}
              12:06
              <br />
              but there are a lot more use cases
            </p>

            <p>
              {" "}
              12:09
              <br />
              even Beyond this so for example let's
            </p>

            <p>
              {" "}
              12:12
              <br />
              say you're you're coming to look at a
            </p>

            <p>
              {" "}
              12:14
              <br />
              particular data set and you want to know
            </p>

            <p>
              {" "}
              12:16
              <br />
              what categories of data are available
            </p>

            <p>
              {" "}
              12:19
              <br />
              and how are they defined a lot of times
            </p>

            <p>
              {" "}
              12:21
              <br />
              you come to a data set you're looking
            </p>

            <p>
              {" "}
              12:23
              <br />
              for data for a particular industry or
            </p>

            <p>
              {" "}
              12:26
              <br />
              state or occupation and you want to know
            </p>

            <p>
              {" "}
              12:29
              <br />
              is that concept even in the data set and
            </p>

            <p>
              {" "}
              12:31
              <br />
              then if it's in the data set how is it
            </p>

            <p>
              {" "}
              12:33
              <br />
              defined if we're looking at Industries
            </p>

            <p>
              {" "}
              12:36
              <br />
              are we talking about NES codes are we
            </p>

            <p>
              {" "}
              12:38
              <br />
              talking about standard industrial
            </p>

            <p>
              {" "}
              12:41
              <br />
              classification codes if you're working
            </p>

            <p>
              {" "}
              12:43
              <br />
              with State data are they state postal
            </p>

            <p>
              {" "}
              12:46
              <br />
              codes are they state names what
            </p>

            <p>
              {" "}
              12:49
              <br />
              occupational code book is being used
            </p>

            <p>
              {" "}
              12:52
              <br />
              etc etc you know you want to know how
            </p>

            <p>
              {" "}
              12:55
              <br />
              it's defined so that then when you're
            </p>

            <p>
              {" "}
              12:57
              <br />
              going to access the data you know what
            </p>

            <p>
              {" "}
              13:00
              <br />
              you can specifically query
            </p>

            <p>
              {" "}
              13:02
              <br />
              on the other thing metadata is really
            </p>

            <p>
              {" "}
              13:04
              <br />
              helpful for is for let's say you're
            </p>

            <p>
              {" "}
              13:07
              <br />
              trying to do some kind of geospatial
            </p>

            <p>
              {" "}
              13:09
              <br />
              analysis what fields in the data set
            </p>

            <p>
              {" "}
              13:12
              <br />
              are location related right and what is
            </p>

            <p>
              {" "}
              13:16
              <br />
              the granularity of location data that's
            </p>

            <p>
              {" "}
              13:18
              <br />
              available how is it defined things of
            </p>

            <p>
              {" "}
              13:21
              <br />
              that nature or if you're looking
            </p>

            <p>
              {" "}
              13:25
              <br />
              let's say at inspections and you want to
            </p>

            <p>
              {" "}
              13:27
              <br />
              know what are the valid ranges of
            </p>

            <p>
              {" "}
              13:30
              <br />
              inspections that I can make an API query
            </p>

            <p>
              {" "}
              13:32
              <br />
              for and so you might want to see that
            </p>

            <p>
              {" "}
              13:35
              <br />
              you can query from dates from 1970 to
            </p>

            <p>
              {" "}
              13:38
              <br />
              present so that's the kind of info
            </p>

            <p>
              {" "}
              13:40
              <br />
              you'll get in the metadata one last
            </p>

            <p>
              {" "}
              13:42
              <br />
              example would be what variables in a
            </p>

            <p>
              {" "}
              13:45
              <br />
              data set are measures so what are we
            </p>

            <p>
              {" "}
              13:47
              <br />
              measuring total wages total number of
            </p>

            <p>
              {" "}
              13:50
              <br />
              employees number of violations number of
            </p>

            <p>
              {" "}
              13:54
              <br />
              inspections so again with metadata you
            </p>

            <p>
              {" "}
              13:56
              <br />
              can quickly you know ass the data set
            </p>

            <p>
              {" "}
              14:00
              <br />
              for many
            </p>

            <p>
              {" "}
              14:02
              <br />
              things so when you download the
            </p>

            <p>
              {" "}
              14:05
              <br />
              data on that individual data set page
            </p>

            <p>
              {" "}
              14:07
              <br />
              you may need a little help understanding
            </p>

            <p>
              {" "}
              14:09
              <br />
              what each of those metadata elements
            </p>

            <p>
              {" "}
              14:11
              <br />
              mean so on the portal up at the top
            </p>

            <p>
              {" "}
              14:15
              <br />
              navigation bar we actually have a
            </p>

            <p>
              {" "}
              14:17
              <br />
              section for metadata and this will help
            </p>

            <p>
              {" "}
              14:20
              <br />
              you understand the metadata schema we're
            </p>

            <p>
              {" "}
              14:22
              <br />
              using how each element is defined and
            </p>

            <p>
              {" "}
              14:25
              <br />
              then there's some practical examples in
            </p>

            <p>
              {" "}
              14:27
              <br />
              there to help you to understand
            </p>

            <p>
              {" "}
              14:29
              <br />
              at this time we have a significant
            </p>

            <p>
              {" "}
              14:33
              <br />
              number of metadata elements but they're
            </p>

            <p>
              {" "}
              14:35
              <br />
              all centered around the substantive
            </p>

            <p>
              {" "}
              14:37
              <br />
              values of a data set or values that have
            </p>

            <p>
              {" "}
              14:40
              <br />
              meaning so that's going to be super
            </p>

            <p>
              {" "}
              14:42
              <br />
              helpful for your use now but I wanted to
            </p>

            <p>
              {" "}
              14:45
              <br />
              let you all know that we are working on
            </p>

            <p>
              {" "}
              14:47
              <br />
              some additional metadata elements that I
            </p>

            <p>
              {" "}
              14:49
              <br />
              think are going to be very helpful for
            </p>

            <p>
              {" "}
              14:52
              <br />
              your analysis so one we're going to
            </p>

            <p>
              {" "}
              14:55
              <br />
              include metadata elements on missing
            </p>

            <p>
              {" "}
              14:58
              <br />
              values so that for each variable in a
            </p>

            <p>
              {" "}
              15:00
              <br />
              data set as a user you can understand
            </p>

            <p>
              {" "}
              15:03
              <br />
              how missing is defined so what how
            </p>

            <p>
              {" "}
              15:06
              <br />
              should you expect missing to be defined
            </p>

            <p>
              {" "}
              15:08
              <br />
              and then what are the patterns of
            </p>

            <p>
              {" "}
              15:10
              <br />
              missingness in a data set in addition
            </p>

            <p>
              {" "}
              15:14
              <br />
              we plan to include metadata
            </p>

            <p>
              {" "}
              15:16
              <br />
              elements for valueless entries we we
            </p>

            <p>
              {" "}
              15:20
              <br />
              refer to that as values in a data set
            </p>

            <p>
              {" "}
              15:22
              <br />
              that appear valid but they don't
            </p>

            <p>
              {" "}
              15:24
              <br />
              actually meet any defined standard so an
            </p>

            <p>
              {" "}
              15:27
              <br />
              example of that would be if we had a
            </p>

            <p>
              {" "}
              15:29
              <br />
              data set that said that it's n's
            </p>

            <p>
              {" "}
              15:32
              <br />
              industry codes and there's a number in
            </p>

            <p>
              {" "}
              15:35
              <br />
              the data set that looks like an industry
            </p>

            <p>
              {" "}
              15:36
              <br />
              code but it doesn't actually meet the
            </p>

            <p>
              {" "}
              15:38
              <br />
              defined standard we're going to have
            </p>

            <p>
              {" "}
              15:41
              <br />
              metadata that will alert the users to
            </p>

            <p>
              {" "}
              15:43
              <br />
              potential data quality problems so that
            </p>

            <p>
              {" "}
              15:46
              <br />
              if there's a particular industry that
            </p>

            <p>
              {" "}
              15:48
              <br />
              doesn't you know meet the defined
            </p>

            <p>
              {" "}
              15:50
              <br />
              standard that will make you aware of
            </p>

            <p>
              {" "}
              15:52
              <br />
              that and lastly we're going to
            </p>

            <p>
              {" "}
              15:54
              <br />
              include elements for descriptive
            </p>

            <p>
              {" "}
              15:56
              <br />
              statistics so that you can get an
            </p>

            <p>
              {" "}
              15:58
              <br />
              understanding the distribution of
            </p>

            <p>
              {" "}
              16:00
              <br />
              various variable variables before you
            </p>

            <p>
              {" "}
              16:03
              <br />
              access the data so lots of really
            </p>

            <p>
              {" "}
              16:07
              <br />
              helpful information here that
            </p>

            <p>
              {" "}
              16:09
              <br />
              comprehensively describes the data
            </p>

            <p>
              {" "}
              <strong>
                Data Preview.See how to preview datasets before downloading.
              </strong>{" "}
            </p>

            <p>
              {" "}
              16:12
              <br />
              sets Okay so underneath of metadata we
            </p>

            <p>
              {" "}
              16:16
              <br />
              are going to have a data preview or we
            </p>

            <p>
              {" "}
              16:18
              <br />
              have one it's a 10 record preview
            </p>

            <p>
              {" "}
              16:21
              <br />
              that'll help you to better understand
            </p>

            <p>
              {" "}
              16:23
              <br />
              the structure organization and format of
            </p>

            <p>
              {" "}
              16:26
              <br />
              the selected data set so this will just
            </p>

            <p>
              {" "}
              16:28
              <br />
              give you
            </p>

            <p>
              {" "}
              16:29
              <br />
              another idea of what the data look like
            </p>

            <p>
              {" "}
              16:32
              <br />
              before you start making your queries
            </p>

            <p>
              {" "}
              16:35
              <br />
              the way I like to think about it is
            </p>

            <p>
              {" "}
              16:37
              <br />
              is like you get to try before you buy so
            </p>

            <p>
              {" "}
              16:39
              <br />
              before you start downloading the
            </p>

            <p>
              {" "}
              <strong>
                API Query Builder.Learn to build custom data queries with our
                API Query Builder interface.
              </strong>{" "}
            </p>

            <p>
              {" "}
              16:42
              <br />
              data okay so underneath the data preview
            </p>

            <p>
              {" "}
              16:45
              <br />
              we have our API query Builder so here's
            </p>

            <p>
              {" "}
              16:47
              <br />
              where it gets fun because you get to
            </p>

            <p>
              {" "}
              16:49
              <br />
              build your own customized data request
            </p>

            <p>
              {" "}
              16:52
              <br />
              and basically the API query Builder
            </p>

            <p>
              {" "}
              16:56
              <br />
              gives you a template for making API
            </p>

            <p>
              {" "}
              16:58
              <br />
              re requests you'll get to select which
            </p>

            <p>
              {" "}
              17:01
              <br />
              fields and Records you want you'll get
            </p>

            <p>
              {" "}
              17:03
              <br />
              to choose how records are formatted and
            </p>

            <p>
              {" "}
              17:05
              <br />
              sorted and then this tool will generate
            </p>

            <p>
              {" "}
              17:08
              <br />
              an API request that you can use to get
            </p>

            <p>
              {" "}
              17:10
              <br />
              your desired
            </p>

            <p>
              {" "}
              17:12
              <br />
              data so here it comes back our waiter
            </p>

            <p>
              {" "}
              17:15
              <br />
              the API says Hey What fields and Records
            </p>

            <p>
              {" "}
              17:17
              <br />
              do you want how should I format and sort
            </p>

            <p>
              {" "}
              17:19
              <br />
              your data so he's waiting for an order
            </p>

            <p>
              {" "}
              17:23
              <br />
              and what this API query Builder is is
            </p>

            <p>
              {" "}
              17:25
              <br />
              like an order form it's your menu you
            </p>

            <p>
              {" "}
              17:27
              <br />
              get to go through the app options you
            </p>

            <p>
              {" "}
              17:29
              <br />
              get to select exactly what it is that
            </p>

            <p>
              {" "}
              17:32
              <br />
              you want and then it'll build the web
            </p>

            <p>
              {" "}
              17:34
              <br />
              request and then you can send that and
            </p>

            <p>
              {" "}
              17:36
              <br />
              get the latest data from the
            </p>

            <p>
              {" "}
              17:38
              <br />
              database so after you build your API
            </p>

            <p>
              {" "}
              17:43
              <br />
              request with the query Builder and you
            </p>

            <p>
              {" "}
              17:45
              <br />
              hit the execute button you're going to
            </p>

            <p>
              {" "}
              17:47
              <br />
              have a couple different options for
            </p>

            <p>
              {" "}
              17:49
              <br />
              accessing your data you can either use
            </p>

            <p>
              {" "}
              17:51
              <br />
              the download button down here to
            </p>

            <p>
              {" "}
              17:53
              <br />
              download the data directly to your
            </p>

            <p>
              {" "}
              17:56
              <br />
              desktop or you can get this
            </p>

            <p>
              {" "}
              17:59
              <br />
              API request URL which is just a web link
            </p>

            <p>
              {" "}
              18:03
              <br />
              that you can put in your web browser you
            </p>

            <p>
              {" "}
              18:06
              <br />
              can put it in the software of your
            </p>

            <p>
              {" "}
              18:08
              <br />
              choice so if you prefer to work in Excel
            </p>

            <p>
              {" "}
              18:11
              <br />
              Excel can make a connection to the API
            </p>

            <p>
              {" "}
              18:13
              <br />
              to get data if you like to use open-
            </p>

            <p>
              {" "}
              18:16
              <br />
              Source software you can do it with
            </p>

            <p>
              {" "}
              18:18
              <br />
              python and R there's lots of options
            </p>

            <p>
              {" "}
              18:21
              <br />
              lots of
            </p>

            <p>
              {" "}
              18:22
              <br />
              flexibility and I won't really go
            </p>

            <p>
              {" "}
              18:24
              <br />
              through the details but and I know it's
            </p>

            <p>
              {" "}
              18:26
              <br />
              kind of small here but the web request
            </p>

            <p>
              {" "}
              18:29
              <br />
              for data is similarly structured to that
            </p>

            <p>
              {" "}
              18:31
              <br />
              of metadata you know where you have like
            </p>

            <p>
              {" "}
              18:34
              <br />
              your agency the data set name the format
            </p>

            <p>
              {" "}
              18:37
              <br />
              and then all those additional
            </p>

            <p>
              {" "}
              18:39
              <br />
              customizations is what we call
            </p>

            <p>
              {" "}
              18:42
              <br />
              parameters in terms of how you want
            </p>

            <p>
              {" "}
              18:45
              <br />
              which Fields you want how you want the
            </p>

            <p>
              {" "}
              18:46
              <br />
              data filtered etc. the tool builds it
            </p>

            <p>
              {" "}
              18:49
              <br />
              all for you so you don't have to worry
            </p>

            <p>
              {" "}
              18:51
              <br />
              about the structure but again just
            </p>

            <p>
              {" "}
              18:54
              <br />
              making you aware of that if you prefer
            </p>

            <p>
              {" "}
              18:57
              <br />
              to work within like a command line
            </p>

            <p>
              {" "}
              18:59
              <br />
              interface the curl command you know may
            </p>

            <p>
              {" "}
              19:01
              <br />
              be advantageous in some
            </p>

            <p>
              {" "}
              19:05
              <br />
              situations so then directly below
            </p>

            <p>
              {" "}
              <strong>
                Code Snippets.Check out handy code snippets to get you started.
              </strong>{" "}
            </p>

            <p>
              {" "}
              19:09
              <br />
              that we have our code Snippets so every
            </p>

            <p>
              {" "}
              19:11
              <br />
              individual data set page will have a
            </p>

            <p>
              {" "}
              19:13
              <br />
              code snippet that will make it really
            </p>

            <p>
              {" "}
              19:16
              <br />
              easy for users that want programmatic
            </p>

            <p>
              {" "}
              19:19
              <br />
              access to get the metadata for a
            </p>

            <p>
              {" "}
              19:21
              <br />
              particular data set or this is set up
            </p>

            <p>
              {" "}
              19:25
              <br />
              just to give you a 10 record sample of a
            </p>

            <p>
              {" "}
              19:27
              <br />
              data set and of course there's lots more
            </p>

            <p>
              {" "}
              19:29
              <br />
              that you can do to customize this is
            </p>

            <p>
              {" "}
              19:31
              <br />
              just to help people to get up and
            </p>

            <p>
              {" "}
              19:33
              <br />
              running so we currently have that
            </p>

            <p>
              {" "}
              19:36
              <br />
              available in R Python and JavaScript
            </p>

            <p>
              {" "}
              19:39
              <br />
              you'll notice here that you will need to
            </p>

            <p>
              {" "}
              19:41
              <br />
              populate your API key so once you copy
            </p>

            <p>
              {" "}
              19:44
              <br />
              and paste these code templates into the
            </p>

            <p>
              {" "}
              19:46
              <br />
              appropriate software get your API key up
            </p>

            <p>
              {" "}
              19:50
              <br />
              above the top navf bar and you should be
            </p>

            <p>
              {" "}
              19:52
              <br />
              good to
            </p>

            <p>
              {" "}
              <strong>
                Benefits of Using the API.Discover how the API can help you work
                with our data more effectively and efficiently.
              </strong>{" "}
            </p>

            <p>
              {" "}
              19:54
              <br />
              go so I want to highlight and summarize
            </p>

            <p>
              {" "}
              19:57
              <br />
              just a couple of the benefits of using
            </p>

            <p>
              {" "}
              19:59
              <br />
              our API that we talked about some of
            </p>

            <p>
              {" "}
              20:02
              <br />
              the pros just get the records that you
            </p>

            <p>
              {" "}
              20:04
              <br />
              need instead of let you know some data
            </p>

            <p>
              {" "}
              20:07
              <br />
              sets can be very large millions of
            </p>

            <p>
              {" "}
              20:09
              <br />
              Records instead of waiting a couple
            </p>

            <p>
              {" "}
              20:11
              <br />
              minutes to download a big file and then
            </p>

            <p>
              {" "}
              20:13
              <br />
              within Excel sorting and filtering out a
            </p>

            <p>
              {" "}
              20:16
              <br />
              subset of Records you need just use the
            </p>

            <p>
              {" "}
              20:19
              <br />
              API you can build all of that processing
            </p>

            <p>
              {" "}
              20:22
              <br />
              into their request and then you have
            </p>

            <p>
              {" "}
              20:25
              <br />
              that link that you can
            </p>

            <p>
              {" "}
              20:26
              <br />
              reuse another integrate data with
            </p>

            <p>
              {" "}
              20:30
              <br />
              your preferred software we already kind
            </p>

            <p>
              {" "}
              20:32
              <br />
              of talked about that having that web
            </p>

            <p>
              {" "}
              20:34
              <br />
              link it's software
            </p>

            <p>
              {" "}
              20:36
              <br />
              agnostic API doesn't care what software
            </p>

            <p>
              {" "}
              20:39
              <br />
              is making the request it just
            </p>

            <p>
              {" "}
              20:42
              <br />
              understands hey I want you to go get
            </p>

            <p>
              {" "}
              20:44
              <br />
              this and then it will return it for you
            </p>

            <p>
              {" "}
              20:47
              <br />
              API our API allows you to automate
            </p>

            <p>
              {" "}
              20:50
              <br />
              your workflows so you can build a a
            </p>

            <p>
              {" "}
              20:53
              <br />
              request once and use it any time I
            </p>

            <p>
              {" "}
              20:57
              <br />
              know that some folks go and download a
            </p>

            <p>
              {" "}
              21:00
              <br />
              file on a weekly basis a monthly basis
            </p>

            <p>
              {" "}
              21:04
              <br />
              quarterly with the API let's say you
            </p>

            <p>
              {" "}
              21:07
              <br />
              were getting something that gets updated
            </p>

            <p>
              {" "}
              21:09
              <br />
              pretty frequently build your request one
            </p>

            <p>
              {" "}
              21:11
              <br />
              time and then you can just put it into
            </p>

            <p>
              {" "}
              21:14
              <br />
              your browser the next week next month
            </p>

            <p>
              {" "}
              21:17
              <br />
              you could connect it to Excel and have a
            </p>

            <p>
              {" "}
              21:19
              <br />
              live feed where you keep the latest
            </p>

            <p>
              {" "}
              21:22
              <br />
              data up to date so this really supports
            </p>

            <p>
              {" "}
              21:25
              <br />
              automation with machine readable data
            </p>

            <p>
              {" "}
              21:27
              <br />
              which I think is a really huge asset to
            </p>

            <p>
              {" "}
              21:30
              <br />
              people and again our the latest
            </p>

            <p>
              {" "}
              21:33
              <br />
              available data is guaranteed when using
            </p>

            <p>
              {" "}
              21:35
              <br />
              our API service and as I had mentioned
            </p>

            <p>
              {" "}
              21:39
              <br />
              the API is especially good with big data
            </p>

            <p>
              {" "}
              21:41
              <br />
              sets so if you had a specific question
            </p>

            <p>
              {" "}
              21:43
              <br />
              like how many ocean inspections were
            </p>

            <p>
              {" "}
              21:46
              <br />
              open in the manufacturing industry in
            </p>

            <p>
              {" "}
              21:49
              <br />
              Cleveland Ohio from 2010 to present you
            </p>

            <p>
              {" "}
              21:52
              <br />
              could build that custom query then the
            </p>

            <p>
              {" "}
              21:55
              <br />
              API will go and get your data and return
            </p>

            <p>
              {" "}
              21:57
              <br />
              that back to you
            </p>

            <p>
              {" "}
              <strong>
                Resources.Access additional materials to support your data
                journey.
              </strong>{" "}
            </p>

            <p>
              {" "}
              22:00
              <br />
              so so we covered a lot and I just
            </p>

            <p>
              {" "}
              22:03
              <br />
              want you to know once you go back to
            </p>

            <p>
              {" "}
              22:05
              <br />
              register where you can find resources to
            </p>

            <p>
              {" "}
              22:07
              <br />
              help you so under the resources menu
            </p>

            <p>
              {" "}
              22:12
              <br />
              bar you will see we have a getting
            </p>

            <p>
              {" "}
              22:15
              <br />
              started page that'll just remind you at
            </p>

            <p>
              {" "}
              22:17
              <br />a 30,000 foot level the steps for
            </p>

            <p>
              {" "}
              22:20
              <br />
              getting your account to start accessing
            </p>

            <p>
              {" "}
              22:22
              <br />
              data the API user guide is our
            </p>

            <p>
              {" "}
              22:25
              <br />
              comprehensive guide for using the API
            </p>

            <p>
              {" "}
              22:27
              <br />
              it'll go through
            </p>

            <p>
              {" "}
              22:29
              <br />
              everything we talked about in much more
            </p>

            <p>
              {" "}
              22:31
              <br />
              detail including data set filtering and
            </p>

            <p>
              {" "}
              22:34
              <br />
              how that works we also have an API
            </p>

            <p>
              {" "}
              22:37
              <br />
              examples page which gives you some more
            </p>

            <p>
              {" "}
              22:40
              <br />
              detailed code examples if you're a
            </p>

            <p>
              {" "}
              22:42
              <br />
              programmer you'll know what I mean by
            </p>

            <p>
              {" "}
              22:44
              <br />
              this we have parameterized code
            </p>

            <p>
              {" "}
              22:47
              <br />
              templates with the full set of
            </p>

            <p>
              {" "}
              22:49
              <br />
              customization that you can pick up and
            </p>

            <p>
              {" "}
              22:51
              <br />
              use to do some more advanced queries
            </p>

            <p>
              {" "}
              22:55
              <br />
              in addition we have an Excel toolkit
            </p>

            <p>
              {" "}
              22:57
              <br />
              there for non-programmers you know to
            </p>

            <p>
              {" "}
              23:00
              <br />
              help them with Excel
            </p>

            <p>
              {" "}
              23:01
              <br />
              integration we also have video
            </p>

            <p>
              {" "}
              23:04
              <br />
              tutorials so we have short and simple
            </p>

            <p>
              {" "}
              23:07
              <br />
              instructional videos for the API at
            </p>

            <p>
              {" "}
              23:10
              <br />
              this time we'll drop a link to the
            </p>

            <p>
              {" "}
              23:12
              <br />
              YouTube playlist in the chat we're
            </p>

            <p>
              {" "}
              23:14
              <br />
              just getting started but those are a
            </p>

            <p>
              {" "}
              23:16
              <br />
              couple videos that can help you and
            </p>

            <p>
              {" "}
              23:19
              <br />
              we'll be adding more videos in the
            </p>

            <p>
              {" "}
              23:21
              <br />
              future we do have a video tutorials page
            </p>

            <p>
              {" "}
              23:24
              <br />
              we're going to be getting the YouTube
            </p>

            <p>
              {" "}
              23:25
              <br />
              videos integrated into here within the
            </p>

            <p>
              {" "}
              23:28
              <br />
              next probably two weeks so this
            </p>

            <p>
              {" "}
              23:30
              <br />
              should be populated soon and then of
            </p>

            <p>
              {" "}
              23:33
              <br />
              course we have those frequently asked
            </p>

            <p>
              {" "}
              <strong>
                Contact Us.Find out how to reach us for further assistance.
              </strong>{" "}
            </p>

            <p>
              {" "}
              23:37
              <br />
              questions and of course we are always
            </p>

            <p>
              {" "}
              23:40
              <br />
              looking for feedback and suggestions
            </p>

            <p>
              {" "}
              23:42
              <br />
              we're here to answer your questions so
            </p>

            <p>
              {" "}
              23:44
              <br />
              there's additional support beyond the
            </p>

            <p>
              {" "}
              23:46
              <br />
              self-help resources using the contact us
            </p>

            <p>
              {" "}
              23:50
              <br />
              you can find that at the top above the
            </p>

            <p>
              {" "}
              23:53
              <br />
              top nav bar and we will be more than
            </p>

            <p>
              {" "}
              23:56
              <br />
              happy to you know know to help out
            </p>

            <p>
              {" "}
              23:59
              <br />
              with anything that you need and if you
            </p>

            <p>
              {" "}
              24:02
              <br />
              have suggestions even things like
            </p>

            <p>
              {" "}
              24:04
              <br />
              suggested data sets I just want you to
            </p>

            <p>
              {" "}
              24:06
              <br />
              know we really value feedback that we're
            </p>

            <p>
              {" "}
              24:09
              <br />
              that we get from our users and we'll
            </p>

            <p>
              {" "}
              24:11
              <br />
              prioritize data sets if we don't know
            </p>

            <p>
              {" "}
              24:13
              <br />
              what data sets you need in the API then
            </p>

            <p>
              {" "}
              24:16
              <br />
              it's going to be hard harder to
            </p>

            <p>
              {" "}
              24:18
              <br />
              prioritize that so we want to hear from
            </p>

            <p>
              {" "}
              24:19
              <br />
              you whether it's that you want to you
            </p>

            <p>
              {" "}
              24:23
              <br />
              know just give feedback on the services
            </p>

            <p>
              {" "}
              24:25
              <br />
              in general we would appreciate that
            </p>

            <p>
              {" "}
              24:27
              <br />
              because we're always looking to impr gr
            </p>

            <p>
              {" "}
              <strong>
                Data Visualization Gallery.Get inspired by our showcase of data
                visualizations.
              </strong>{" "}
            </p>

            <p>
              {" "}
              24:29
              <br />I also wanted to mention that we have a
            </p>

            <p>
              {" "}
              24:32
              <br />
              visualization Gallery where you can
            </p>

            <p>
              {" "}
              24:34
              <br />
              interactively explore our data so
            </p>

            <p>
              {" "}
              24:37
              <br />
              we're just starting to build this but
            </p>

            <p>
              {" "}
              24:39
              <br />
              there's a couple in there to to
            </p>

            <p>
              {" "}
              24:42
              <br />
              explore right now
            </p>
          </div>
        </>
      );

    default:
  }
}

export default TutorialText;
