/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Col, Row, Container, Card, Form,
} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import mutationObserver from './data/MutationObserver';
import ContentComponent from './data/ContentComponent';
import HeroBanner from './StaticComponents/HeroBanner';

import '../css/test.css';

class Dataset extends React.Component {
  observerFunction = mutationObserver();

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sortValue: 'name',
      agency: '',
      tag: '',
      tagsAsc: true,
      sortBy: '',
      category: '',
      name: '',
      filterBy: '',
      order: 'asc',
    };
  }

  componentDidMount() {
    const locationQuery = this.props.location.search;
    if (locationQuery) this.triggerChanges(this.props);
    else this.handleAPICall();
  }

  componentWillReceiveProps(nextProps) {
    this.triggerChanges(nextProps);
  }

  triggerChanges = (nextProps) => {
    const locationQuery = nextProps.location.search;
    if (locationQuery) {
      const queryValues = queryString.parse(locationQuery);
      const { filterBy = '', name = '', page = 1 } = queryValues || {};
      let key = '';
      switch (filterBy) {
        case 'tag':
          key = 'tags';
          break;
        case 'agency':
          key = 'agencies';
          break;
        case 'category':
          key = 'categories';
          break;
        default:
          key = null;
          break;
      }
      if (nextProps.tags && nextProps.agencies && nextProps.categories) {
        if (
          this.state.name !== name
          || this.state.page !== page
          || this.state.filterBy !== filterBy
        ) {
          const selectedEntity = Array.isArray(nextProps[key])
            && nextProps[key].find((ent) => ent.name.trim() === name.trim());
          this.setState(
            {
              name,
              filterBy,
              tag: filterBy === 'tag' ? selectedEntity : null,
              agency: filterBy === 'agency' ? selectedEntity : null,
              category: filterBy === 'category' ? selectedEntity : null,
              page,
            },
            this.handleAPICall(),
          );
        }
      }
    }
  };

  handlePageChange = (page) => {
    const { filterBy, name } = this.state;
    this.props.history.push(
      `${window.location.pathname}?filterBy=${filterBy}&name=${name}&page=${page}`,
    );
  };

  handleSortChange = ({ sortValue }) => {
    let sortOrder = 'asc';
    let sortVal = sortValue;

    if (sortValue === '-name') {
      sortVal = 'name';
      sortOrder = 'desc';
    }
    this.setState({
      sortValue: sortVal || this.state.sortValue,
    });

    this.setState(
      {
        order: sortOrder,
      },
      () => {
        this.handleAPICall();
      },
    );
  };

  filterByAgency = (agency) => {
    this.props.history.push(
      `${window.location.pathname}?filterBy=agency&name=${agency.name}`,
    );
    this.handleAPICall();
  };

  filterByTag = (tag) => {
    this.props.history.push(
      `${window.location.pathname}?filterBy=tag&name=${tag.name}`,
    );
    this.handleAPICall();
  };

  filterByCategory = (category) => {
    this.props.history.push(
      `${window.location.pathname}?filterBy=category&name=${category.name}`,
    );
    this.handleAPICall();
  };

  clearFilter = () => {
    this.props.history.push(
      `${window.location.pathname}?filterBy=null&name=null&page=1`,
    );
    this.setState(
      {
        agency: null,
        tag: null,
      },
      this.handleAPICall(),
    );
  };

  handleAPICall = () => {
    const {
      page, sortValue, agency, tag, filterBy, category, order,
    } = this.state;

    let paramUrl = '';

    if (!window.location.search) {
      paramUrl = `?page=${page}`;
    }
    if (sortValue) paramUrl += `&sortby=${sortValue}`;
    if (order) paramUrl += `&order=${order}`;
    const url = window.location.search + paramUrl;
    this.props.fetchDataSets(url);
  };

  sortTags = () => {
    this.setState({ tagsAsc: !this.state.tagsAsc });
  };

  getFilterDisplayVal = () => {
    let filteredByTextDisplayed;

    if (this.state.agency) {
      filteredByTextDisplayed = `Agency > ${this.state.agency.abbr}`;
    } else if (this.state.tag) {
      filteredByTextDisplayed = `Tag > ${this.state.tag.name}`;
    } else if (this.state.category) {
      filteredByTextDisplayed = `Category > ${this.state.category.name}`;
    }
    return filteredByTextDisplayed;
  };

  render() {
    this.observerFunction.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    const noTitle = true;
    let agenciesSorted;
    let categoriesSorted;
    if (this.props.agencies) {
      agenciesSorted = this.props.agencies.sort((a, b) => {
        const sa = a.name.toLowerCase();
        const sb = b.name.toLowerCase();
        if (sa < sb) {
          return -1;
        }
        if (sa > sb) {
          return 1;
        }
        return 0;
      });
    }

    if (this.props.categories) {
      categoriesSorted = this.props.categories.sort((a, b) => {
        const sa = a.name.toLowerCase();
        const sb = b.name.toLowerCase();
        if (sa < sb) {
          return -1;
        }
        if (sa > sb) {
          return 1;
        }
        return 0;
      });
    }
    return (
      <>
        <ContentComponent
          identifier="mainTitle"
          spanText="Datasets"
          centered={false}
          title={noTitle}
        />
        <HeroBanner page="dataset" />
        <Container className="mt-4 p-4">
          {this.props.datasets && !this.props.dataSetLoading ? (
            <Row>
              <Col
                className="d-none d-md-block bg-light border"
                md={2}
                role="navigation"
              >
                {this.props.agencies ? (
                  <div>
                    <div>
                      <a
                        data-toggle="collapse"
                        data-target="#collapseExample3"
                        aria-expanded="false"
                        aria-controls="collapseExample3"
                        role="button"
                        href="javascript:void(0)"
                      >
                        <Row
                          id="categories_sidebar"
                          className="pt-3 pb-2 pl-3 border border-right-0 border-left-0"
                        >
                          <Col>
                            <u>
                              <h3>Categories</h3>
                            </u>
                          </Col>
                          <Col className="text-right">
                            <i className="fa fa-angle-down" />
                          </Col>
                        </Row>
                      </a>
                      <div className="collapse" id="collapseExample3">
                        {categoriesSorted?.map((cat) => (
                          <Row className="pt-2 pb-2 pl-3 link_nav">
                            <a
                              href="#mainTitle"
                              onClick={() => this.filterByCategory(cat)}
                              className="text-dark small sub-categories"
                            >
                              {cat.name}
                            </a>
                          </Row>
                        ))}
                      </div>
                    </div>
                    <div>
                      <a
                        data-toggle="collapse"
                        data-target="#collapseExample2"
                        aria-expanded="false"
                        aria-controls="collapseExample2"
                        role="button"
                        href="javascript:void(0)"
                      >
                        <Row
                          id="tags_sidebar"
                          className="pt-3 pb-2 pl-3 border border-right-0 border-left-0"
                        >
                          <Col>
                            <u>
                              <h3>Tags</h3>
                            </u>
                          </Col>
                          <Col className="text-right">
                            <i className="fa fa-angle-down text-right" />
                          </Col>
                        </Row>
                      </a>
                      <div
                        className="collapse tag-side pt-3 pb-2 pl-3 border border-right-0 border-top-0 border-left-0"
                        id="collapseExample2"
                      >
                        <Row className="justify-content-around">
                          <span>
                            <strong>Sort</strong>
                          </span>
                          <a
                            href="javascript:void(0)"
                            onClick={() => this.sortTags()}
                          >
                            <Col>
                              <strong>
                                <span>
                                  {this.state.tagsAsc ? 'A - Z ' : 'Z - A '}
                                </span>
                              </strong>
                              <i className="fa fa-sort" />
                            </Col>
                          </a>
                        </Row>
                        {this.props.tags
                          && this.props.tags
                            .sort((a, b) => (this.state.tagsAsc
                              ? a.name
                                .toLowerCase()
                                .localeCompare(b.name.toLowerCase())
                              : b.name
                                .toLowerCase()
                                .localeCompare(a.name.toLowerCase())))
                            .map((tag) => (
                              <Row className="pt-2 pb-2 pl-2 link_nav">
                                <a
                                  href="#mainTitle"
                                  className="text-dark small"
                                  onClick={() => this.filterByTag(tag)}
                                >
                                  {tag.name}
                                </a>
                              </Row>
                            ))}
                      </div>
                    </div>
                    <div>
                      <a
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        role="button"
                        href="javascript:void(0)"
                      >
                        <Row
                          id="agencies_sidebar"
                          className="pt-3 pb-2 pl-3 border border-right-0 border-left-0 border-top-0"
                        >
                          <Col>
                            <u>
                              <h3>Agencies</h3>
                            </u>
                          </Col>
                          <Col className="text-right">
                            <i className="fa fa-angle-down" />
                          </Col>
                        </Row>
                      </a>
                      <div className="collapse" id="collapseExample">
                        {agenciesSorted?.map((agency) => (
                          <Row className="pt-2 pb-2 pl-3 link_nav">
                            <a
                              href="#mainTitle"
                              className="text-dark small sub-categories"
                              onClick={() => this.filterByAgency(agency)}
                            >
                              {agency.name}
                              {` (${agency.abbr})`}
                            </a>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Col>
              <Col md={10} className="pl-5">
                {this.props.datasets.length > 0 ? (
                  <div className="col-12">
                    <Row className="align-items-end">
                      <Col>
                        {this.props.metaSets.total_count}
                        <span> Results</span>
                        {this.state.agency
                        || this.state.tag
                        || this.state.category ? (
                          <span>
                            <span> filtered by </span>
                            <span
                              id="datasets_filter_tag"
                              className="px-2 py-1 small text-white mr-2"
                            >
                              {this.getFilterDisplayVal()}
                            </span>

                            <button
                              className="border border-secondary rounded small px-2 py-1 text-secondary clear_filter"
                              onClick={() => this.clearFilter()}
                            >
                              clear filter
                            </button>
                          </span>
                          ) : null}
                      </Col>
                      <Col lg={4}>
                        <Form>
                          <Form.Row>
                            <Col>
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="mt-0">
                                  Sort By
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  onChange={({ target: { value } }) => this.handleSortChange({ sortValue: value })}
                                >
                                  <option value="name">Name ascending</option>
                                  <option value="-name">Name descending</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <span>
                      <span>No result displayed, filtered by </span>
                      {this.state.agency
                      || this.state.tag
                      || this.state.category ? (
                        <span
                          id="datasets_filter_tag"
                          className="px-2 py-1 small text-white mr-2"
                        >
                          {this.getFilterDisplayVal()}
                        </span>
                        ) : null}

                      <button
                        className="border border-secondary rounded small px-2 py-1 text-secondary clear_filter"
                        onClick={() => this.clearFilter()}
                      >
                        clear filters
                      </button>
                    </span>
                    <h2>No Dataset found!</h2>
                  </div>
                )}
                {this.props.datasets ? (
                  <>
                    {this.props.datasets.map((dataset, idx) => (
                      <Row key={idx} id={`dataset${idx + 1}`}>
                        <Col lg={12}>
                          <Card className="mt-3">
                            <Card.Header className="update">
                              <Row className="justify-content-between">
                                <Col
                                  xs={8}
                                  lg={8}
                                  id={`datasetmainTitle${idx + 1}`}
                                >
                                  <b>{dataset.agency.name}</b>
                                </Col>
                                <Col className="text-right" xs={4} lg={4}>
                                  <a
                                    className="p-1 rounded agency_abv"
                                    href="#mainTitle"
                                    onClick={() => this.filterByAgency(dataset.agency)}
                                  >
                                    {dataset.agency.abbr.toUpperCase()}
                                  </a>
                                </Col>
                              </Row>
                            </Card.Header>
                            <Card.Body>
                              <Card.Title>
                                <Link to={`/datasets/${dataset.id}`}>
                                  <u>
                                    <h2>{dataset.name}</h2>
                                  </u>
                                </Link>
                              </Card.Title>
                              {dataset.description ? (
                                dataset.description.length > 300 ? (
                                  <Card.Text>
                                    <div id="module" className="container">
                                      <p
                                        className="collapse"
                                        id={`singleDataset${idx}`}
                                        aria-expanded="false"
                                      >
                                        {dataset.description}
                                      </p>
                                      <a
                                        role="button"
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={`#singleDataset${idx}`}
                                        aria-expanded="false"
                                        aria-controls={`singleDataset${idx}`}
                                        href="javascript:void(0)"
                                      />
                                    </div>
                                  </Card.Text>
                                ) : (
                                  <Card.Text>{dataset.description}</Card.Text>
                                )
                              ) : (
                                <Card.Text>
                                  Dataset has no description
                                </Card.Text>
                              )}
                            </Card.Body>
                            <Card.Footer>
                              Tags:
                              {dataset.tag_list
                                .sort((a, b) => {
                                  const lowerA = a.toLowerCase();
                                  const lowerB = b.toLowerCase();
                                  if (lowerA === lowerB) {
                                    return a < b ? -1 : 1;
                                  }
                                  return lowerA < lowerB ? -1 : 1;
                                })
                                .map((tag) => (
                                  <span
                                    className="px-1 mx-1 pb-1 small rounded agency_abv"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <a
                                      href="#mainTitle"
                                      onClick={() => this.filterByTag({ name: tag })}
                                    >
                                      {tag.toUpperCase()}
                                    </a>
                                  </span>
                                ))}
                            </Card.Footer>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                    <Row className="justify-content-end mt-3 col-10">
                      <Col>
                        {this.props.datasets.length > 0
                          && this.props.metaSets && (
                            <Row className="justify-content-center">
                              <Pagination
                                activePage={this.props.metaSets.current_page}
                                itemsCountPerPage={10}
                                totalItemsCount={
                                  this.props.metaSets.total_count
                                }
                                onChange={this.handlePageChange}
                                hideNavigation="false"
                                hideFirstLastPages="false"
                              />
                            </Row>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          ) : this.props.dataSetLoading ? (
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          ) : (
            <h1>Page not available</h1>
          )}
        </Container>
      </>
    );
  }
}
export default withRouter(Dataset);
