/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import Card from 'react-bootstrap/Card';
import HeroBanner from './HeroBanner';
import PreContentComponent from '../data/PreContentComponent';
import '../../css/visualization.css';

function VisualizationGallery(props) {
  const [htmlData, setHtmlData] = useState('<div></div>');

  // FILTER CODE
  // const countPageRefresh = 0;

  // var filteredDropdown = $("#filtered_dropdown");
  // var topicDropdown = $("#topic_dropdown");

  // function updateTopicDropdown(topics) {
  //   topicDropdown.empty();
  //   topicDropdown.append("<option>(Show all topics)</option>");
  //   let uniqueTopics = Array.from(new Set(topics));
  //   uniqueTopics.sort();
  //   uniqueTopics.forEach(function (topic) {
  //     topicDropdown.append("<option>" + topic + "</option>");
  //   });
  // }

  // function applyFilters() {
  //   var selectedAgency = filteredDropdown.val();
  //   var selectedTopic = topicDropdown.val();

  //   $(".card").parent().hide();

  //   console.log("change", selectedAgency, selectedTopic);

  //   if (
  //     selectedAgency === "(Show all agencies)" &&
  //     selectedTopic === "(Show all topics)"
  //   ) {
  //     $(".card").parent().show();
  //     console.log("all");
  //   } else if (
  //     selectedAgency === "(Show all agencies)" &&
  //     selectedTopic !== "(Show all topics)"
  //   ) {
  //     $(".card:contains('" + selectedTopic + "')")
  //       .parent()
  //       .show();
  //   } else if (
  //     selectedAgency !== "(Show all agencies)" &&
  //     selectedTopic === "(Show all topics)"
  //   ) {
  //     $(".card:contains('" + selectedAgency + "')")
  //       .parent()
  //       .show();
  //   } else {
  //     $(".card:contains('" + selectedAgency + "')")
  //       .filter(":contains('" + selectedTopic + "')")
  //       .parent()
  //       .show();
  //   }
  // }

  // topicDropdown.change(function () {
  //   applyFilters();
  //   var selectedAgency = filteredDropdown.val();
  //   var selectedTopic = topicDropdown.val();
  //   if (selectedTopic !== "(Show all topics)") {
  //     var agenciesWithSelectedTopic = [];
  //     Object.keys(filteredOptions).forEach(function (agency) {
  //       if (filteredOptions[agency].includes(selectedTopic)) {
  //         agenciesWithSelectedTopic.push(agency);
  //       }
  //     });
  //     updateAgencyDropdown(agenciesWithSelectedTopic);
  //   } else {
  //     updateAgencyDropdown(Object.keys(filteredOptions));
  //   }
  //   //filteredDropdown.val('')
  //   filteredDropdown.val(selectedAgency);
  // });

  // filteredDropdown.change(function () {
  //   var selectedAgency = filteredDropdown.val();
  //   var selectedTopic = topicDropdown.val();

  //   if (selectedAgency === "(Show all agencies)") {
  //     updateTopicDropdown(Object.values(filteredOptions).flat());
  //   } else {
  //     updateTopicDropdown(filteredOptions[selectedAgency]);
  //   }
  //   topicDropdown.val(selectedTopic);
  //   applyFilters();
  // });

  // var filteredOptions = {
  //   "Employee Benefits Security Administration (EBSA)": [
  //     "ERISA",
  //     "Form 5500",
  //     "Benefit Plan",
  //   ],
  //   "Employment and Training Administration (ETA)": [
  //     "Trade Act",
  //     "Eligibility",
  //     "Participant",
  //     "Outcomes",
  //     "Funding",
  //     "Workers",
  //     "State",
  //     "Petitions",
  //     "Determinations",
  //     "Certifications",
  //     "Workers",
  //     "Financial",
  //     "Grantees",
  //   ],
  //   "Office of Disability Employment Policy (ODEP)": [
  //     "Disability",
  //     "Employment-Population Ratio",
  //     "Workers",
  //     "State",
  //     "Race/Ethnicity",
  //     "Earnings",
  //     "Sex",
  //   ],
  //   "Veterans Employment and Training Service (VETS)": [
  //     "Veterans",
  //     "Unemployment Rate",
  //     "State",
  //   ],
  //   "Wage and Hour Division (WHD)": [
  //     "FLSA",
  //     "Back Wages",
  //     "Industries",
  //     "Workers",
  //   ],
  // };

  // Initialize dropdowns

  useEffect(() => {
    let pageName;
    (async () => {
      if (
        props.location.pathname === '/visualization-gallery/'
        || props.location.pathname === '/visualization-gallery'
      ) {
        pageName = '/static/visualization-gallery.html';
      } else {
        pageName = `/static${props.location.pathname}`;
      }
      const response = await fetch(pageName);
      const text = await response.text();
      setHtmlData(text);
      // FILTER CODE
      // function updateAgencyDropdown(agencies) {
      //   filteredDropdown.empty();
      //   filteredDropdown.append("<option>(Show all agencies)</option>");
      //   agencies.forEach(function (agency) {
      //     filteredDropdown.append("<option>" + agency + "</option>");
      //   });
      // }
      // var filteredOptions = {
      //   "Employee Benefits Security Administration (EBSA)": [
      //     "ERISA",
      //     "Form 5500",
      //     "Benefit Plan",
      //   ],
      //   "Employment and Training Administration (ETA)": [
      //     "Trade Act",
      //     "Eligibility",
      //     "Participant",
      //     "Outcomes",
      //     "Funding",
      //     "Workers",
      //     "State",
      //     "Petitions",
      //     "Determinations",
      //     "Certifications",
      //     "Workers",
      //     "Financial",
      //     "Grantees",
      //   ],
      //   "Office of Disability Employment Policy (ODEP)": [
      //     "Disability",
      //     "Employment-Population Ratio",
      //     "Workers",
      //     "State",
      //     "Race/Ethnicity",
      //     "Earnings",
      //     "Sex",
      //   ],
      //   "Veterans Employment and Training Service (VETS)": [
      //     "Veterans",
      //     "Unemployment Rate",
      //     "State",
      //   ],
      //   "Wage and Hour Division (WHD)": [
      //     "FLSA",
      //     "Back Wages",
      //     "Industries",
      //     "Workers",
      //   ],
      // };
      // updateTopicDropdown(Object.values(filteredOptions).flat());
      // updateAgencyDropdown(Object.keys(filteredOptions));
      // applyFilters();
    })();
  }, []);
  return (
    <div className="pre-content">
      <PreContentComponent spanText="Visualization Gallery" />
      <HeroBanner page="visualization" />
      <div
        className="views-element-container"
        id="block-views-block-homepage-hero-block-1"
      >
        <div className="block-views-block-homepage-hero-block-1">
          <div className="js-view-dom-id-0176fc946b1c2df0b5f911bd6146810094db8e6897febb20c3aa9c8fac3db926">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Row>
                    <Col>
                      <div id="desc_text">
                        <h2>About The Gallery</h2>
                        Explore our data visualizations to discover trends and
                        better understand the impact of our programs.
                      </div>
                      {/* <p>
                        <label htmlFor="filtered_dropdown">
                          Filter by agency:{" "}
                        </label>{" "}
                        <select id="filtered_dropdown">
                          <option value="sel">(Show all agencies)</option>
                        </select>
                      </p>

                      <p>
                        <label htmlFor="topic_dropdown">
                          Filter by topic:{" "}
                        </label>{" "}
                        <select id="topic_dropdown">
                          <option>(Show all topics)</option>
                        </select>
                      </p> */}
                    </Col>
                  </Row>
                  <div className="row" id="gallery_row">
                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/ebsa-chart1.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Private Pension Plans
                              <div className="visualizations_teaser_caption_sub">
                                1975 - 2021
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Learn about trends in defined benefit and
                            contribution plan counts since 1975
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Erisa, Form 5500, Benefit Plan
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/odg/visualization-gallery/ebsa-private-pension-plans"
                            className="explore_button"
                            variant="primary"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/vets-map.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Veterans Unemployment Rate
                              <div className="visualizations_teaser_caption_sub">
                                2014 - 2023
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Explore the variation in veterans unemployment rates
                            by state over time.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Veterans, Unemployment Rate, State
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/odg/visualization-gallery/vets-unemployment-rate"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/whd-chart1-a.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Back Wages in Low-Wage, High-Violation industries
                              <div className="visualizations_teaser_caption_sub">
                                FY2013 - FY2024
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            View the evolution of back wages recovered for
                            workers by industry.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            FLSA, Back Wages, Industries, Workers
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/odg/visualization-gallery/whd-low-wage-high-violation-industries"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/eta_taa1_1.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Trade Adjustment Assistance Program Dashboard
                              <div className="visualizations_teaser_caption_sub">
                                FY2010 - FY2023
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Learn about the eligibility, funding, participation,
                            and outcomes of the TAA program.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Trade Act, Eligilibility, Participants, Outcomes,
                            Funding, Workers, State
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/eta/tradeact/data"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/eta-taa2.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Trade Adjustment Assistance Petition Dashboard
                              <div className="visualizations_teaser_caption_sub">
                                FY2009 - Present
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Get information about worker groups that have been
                            certified or denied eligibility.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Trade Act, Petitions, Determinations,
                            Certifications, Workers
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/eta/tradeact/data/petitions-determinations"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/eta_taa3_2.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              Trade Adjustment Assistance Funding Allocations
                              Dashboard
                              <div className="visualizations_teaser_caption_sub">
                                FY2009 - FY2023
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Explore the funding provided to states for the TAA
                            program and its benefits.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Trade Act, Financial, Grantees, State
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/eta/tradeact/data/financial-data"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/odep-eprmap.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              EMPLOYMENT-POPULATION RATIO FOR PEOPLE WITH
                              DISABILITIES AGES 18-64
                              <div className="visualizations_teaser_caption_sub">
                                2016 - 2020 period estimate
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Explore the employment rate for people with
                            disabilities by state and race/ethnicity,
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Disability, Employment-Population Ratio, Workers,
                            State, Race/Ethnicity
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/odep/research-evaluation/EPRmap"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="viz_outer_div">
                      <Card>
                        <div className="visSquare">
                          <div className="visualizations_teaser">
                            <img
                              className="visualizations_teaser_img"
                              src={require('../../images/odep-maemap.png')}
                              alt="Map"
                            />
                            <div className="visualizations_teaser_caption">
                              MEDIAN ANNUAL EARNINGS FOR WORKERS WITH
                              DISABILITIES
                              <div className="visualizations_teaser_caption_sub">
                                2016 - 2020 period estimate
                              </div>
                            </div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            Explore median annual earnings for workers with
                            disabilities by state and sex.
                          </Card.Text>
                          <p>
                            <b>Topics: </b>
                            Disability, Earnings, Workers, State, Sex
                          </p>
                          <Button
                            href="https://www.dol.gov/agencies/odep/research-evaluation/MAEmap"
                            variant="primary"
                            className="explore_button"
                            target="_blank"
                          >
                            Explore
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisualizationGallery;
